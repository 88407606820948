/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/material.theme.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-cell {
  vertical-align: middle;
}

.dialog-content {
  overflow-x: auto;
}


.mat-card-title {
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

mat-radio-group {
  display: flex;
  align-items: flex-end;
}

h6, .h6 {
  font-size: 14px !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  color: hsl(0deg 0% 37%) !important;
  font-weight: 500 !important;
}

#sidebarMenu {
  box-shadow: inset -1px 0 0 rgb(0 0 0 / 10%) !important;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

::-webkit-scrollbar-thumb {
background-color: rgb(188, 194, 199);
}

.clear_document {
  position: absolute;
  right: 15px;
  top: 6px;
  color: rgb(158, 158, 158);
  cursor: pointer;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
  caret-color: transparent;
}

.action-menu-item .mat-menu-item {
  border-bottom: 1px solid #e8e8e8;
}

.action-menu-item .mat-menu-item:last-child {
  border-bottom: none;
}

.action-menu a {
  color: gray;
  cursor: pointer;
}

.remitaanceReportTable{
  width: 100%;
  overflow: scroll;
}
.remitaanceReportTable{
  td.mat-cell {
    border-bottom-style:dashed;
    border-bottom-width: 2px;
  }
}

.reportTitles{
  font-weight:500;
}

.action-button-menu .mat-menu-item {
  border: 1px solid #d8d8d8 !important;
  font-size: 11px !important;
  line-height: 30px !important;
  height: 30px !important;
  padding: 0 10px !important;
  float: left !important;
  border-radius: 3px;
  margin: 2px;
}

.action-button-menu .mat-menu-item .mat-icon {
  font-size: 12px !important;
    margin-right: -8px !important;
    margin-top: 7px !important;
}

.mat-column-number {
  width: 5% !important;
}

.mat-column-actions {
  width: 13% !important;
}

.receipt .list-group-item {
  padding: 0.2rem .5rem !important;
}

.receipt {
  font-size: 11px !important;
}

.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #52b0d1 !important;
}

.text-gray {
  color: #607D8B !important;
}